const auth = [
  { action: 'read', subject: 'Auth' }
]

const adminPermission = { action: 'read', subject: 'Admin' }
const processCMStatusPermission = { action: 'process', subject: 'CM status' }
const orderUpdateOperationsUserPermission = { action: 'update', subject: 'Order Operations User' }
const createQuotePermission = { action: 'create', subject: 'Quote' }
const customCurtainBrandPermissions = [
  { action: 'add', subject: 'Custom Curtain Brand' },
  { action: 'update', subject: 'Custom Curtain Brand' },
  { action: 'delete', subject: 'Custom Curtain Brand' }
]
const customCurtainRangePermissions = [
  { action: 'add', subject: 'Custom Curtain Range' },
  { action: 'update', subject: 'Custom Curtain Range' },
  { action: 'delete', subject: 'Custom Curtain Range' }
]

const mySalesDashboardPermission = { action: 'read', subject: 'My Sales Dashboard' }
const salesDashboardPermission = { action: 'read', subject: 'Sales Dashboard' }
const samplesDashboardPermission = { action: 'read', subject: 'Samples Dashboard' }
const operationsSalesDashboardPermission = { action: 'read', subject: 'Operations Sales Dashboard' }
const operationsBookingsDashboardPermission = { action: 'read', subject: 'Operations Bookings Dashboard' }
const dashboardTargetsPermission = { action: 'read', subject: 'Dashboard Targets' }

const admin = [
  { ...adminPermission },
  { ...processCMStatusPermission },
  { action: 'view', subject: 'Item image' },
  { action: 'update', subject: 'Order status' },
  ...customCurtainBrandPermissions,
  ...customCurtainRangePermissions,
]

// initial (guest) with logged in abilities
const INITIAL = [
  { action: 'read', subject: 'Guest' }
]

// Abilities for the test user
const TEST = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Abilities for the installer
const INSTALLER = [
  ...auth,
  { ...processCMStatusPermission },
  { action: 'read', subject: 'Installer' },
  { action: 'view', subject: 'Item image' },
  { action: 'add', subject: 'Item image' },
  { action: 'update', subject: 'Item image' },
  { action: 'delete', subject: 'Item image' },
  { action: 'update', subject: 'Check Measure Product' }
]

// Design consulatant abilities
const DESIGN_CONSULTANT = [
  ...auth,
  { ...adminPermission },
  { ...mySalesDashboardPermission }
]

// Acconts abilities
const ACCOUNTS = [
  ...auth,
  ...admin,
  { ...salesDashboardPermission }
]

// Acconts abilities
const ACCOUNTS_MANAGER_OR_FINANCE_MANAGER = [
  ...auth,
  ...admin,
  { ...salesDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Acconts receivable officer abilities
const ACCOUNTS_RECEIVABLE_OFFICER = [
  ...auth,
  ...admin,
  { ...salesDashboardPermission }
]


// Chief Financial Officer abilities
const CHIEF_FINANCIAL_OFFICER = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Product Specialist abilities
const PRODUCT_SPECIALIST = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission }
]

// Digital manager abilities
const DIGITAL_MANAGER = [
  ...auth,
  ...admin,
  { ...createQuotePermission },
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// OCE abilities
const OCE = [
  ...auth,
  ...admin,
  { ...createQuotePermission },
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Director abilities
const DIRECTOR = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// HR abilities
const HR = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
]

// operations manager abilities
const OPERATIONS_MANAGER = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// marketing manager abilities
const MARKETING_MANAGER = [
  ...auth,
  ...admin,
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission }
]

// Operations Analyst abilities
const OPEATIONS_ANALYST = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission }
]

// sales manager abilities
const SALES_MANAGER = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...samplesDashboardPermission },
  { ...operationsSalesDashboardPermission },
  { ...operationsBookingsDashboardPermission },
  { ...dashboardTargetsPermission }
]

// sales admin support abilities
const SALES_ADMIN_SUPPORT = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission }
]

// Senior Design Consultant abilities
const SENIOR_DESIGN_CONSULTANT = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission }
]

// Senior Design Consultant abilities
const SENIOR_DESIGN_CONSULTANT_TEAM_LEADER = [
  ...auth,
  ...admin,
  { ...mySalesDashboardPermission },
  { ...salesDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Sample team leader abilities
const SAMPLES_TEAM_LEADER = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...samplesDashboardPermission },
  { ...dashboardTargetsPermission }
]

// Sampler abilities
const SAMPLER = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...samplesDashboardPermission }
]

// Marketing coordinator abilities
const MARKETING_COORDINATOR = [
  ...auth,
  ...admin,
  { ...salesDashboardPermission }
]

// Operations Administrator abilities
const OPERATIONS_ADMINISTRATOR = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...operationsSalesDashboardPermission }
]

// Operations Scheduler abilities
const OPERATIONS_SCHEDULAR = [
  ...auth,
  ...admin,
  { ...orderUpdateOperationsUserPermission },
  { ...operationsBookingsDashboardPermission }
]

const Abilities = {
  INITIAL,
  TEST,
  INSTALLER,
  ACCOUNTS,
  DIRECTOR,
  HR,
  OCE,
  SAMPLER,
  SALES_MANAGER,
  DIGITAL_MANAGER,
  OPEATIONS_ANALYST,
  MARKETING_MANAGER,
  DESIGN_CONSULTANT,
  SAMPLES_TEAM_LEADER,
  OPERATIONS_MANAGER,
  PRODUCT_SPECIALIST,
  SALES_ADMIN_SUPPORT,
  OPERATIONS_SCHEDULAR,
  MARKETING_COORDINATOR,
  CHIEF_FINANCIAL_OFFICER,
  OPERATIONS_ADMINISTRATOR,
  SENIOR_DESIGN_CONSULTANT,
  ACCOUNTS_RECEIVABLE_OFFICER,
  SENIOR_DESIGN_CONSULTANT_TEAM_LEADER,
  ACCOUNTS_MANAGER_OR_FINANCE_MANAGER
}

export default Abilities
