import Api from '@/services/Api'

export default {
  createNotification (notification) {
    return Api().post('notifications/', notification)
  },
  createNotificationsForTaggedUsers (notification) {
    return Api().post('notificationsForTaggedUsers/', notification)
  },
  readNotification (id) {
    return Api().put(`notifications/${id}/read`)
  },
  readAllNotificationsOfUser (userID) {
    return Api().put(`notifications/${userID}/readAll`)
  },
  deleteNotification (id) {
    return Api().put(`notifications/${id}/delete`)
  },
  getNotificationsOfUser (userID, filter) {
    return Api().get(`notifications/${userID}/${filter}`)
  },
  getUnreadNotificationsCount (userID) {
    return Api().get(`unreadNotificationsCount/${userID}`)
  }
}
