import Vue from 'vue'
import Router from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { routes as reworkRouutes } from './reworks'
import { env } from '@/env'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 } // always scroll to top when page change
  },
  routes: [
    {
      path: '/',
      name: 'Login',
      component: () => import('@/components/Login'),
      meta: { title: 'Login', resource: 'Guest', action: 'read', layout: 'full' }
    },
    {
      path: '/password/forgot',
      name: 'ForgotPassword',
      component: () => import('@/components/ForgotPassword'),
      meta: { title: 'Forgot Password', resource: 'Guest', action: 'read', layout: 'full' }
    },
    {
      path: '/password/reset',
      name: 'ResetPassword',
      component: () => import('@/components/ResetPassword'),
      meta: { title: 'Reset Password', resource: 'Guest', action: 'read', layout: 'full' }
    },
    {
      path: '/updatePassword',
      name: 'Update Password',
      component: () => import('@/components/admin/UpdatePassword'),
      meta: {
        title: 'Update Password',
        resource: 'Auth',
        action: 'read'
      }
    },
    {
      path: '/my-sales',
      name: 'MySales',
      component: () => import('@/components/MySales'),
      meta: {
        title: 'My Sales',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/sales-users',
      name: 'Sales User',
      component: () => import('@/components/admin/SalesUsers'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/sentToTWC',
      name: 'SentToTWC',
      component: () => import('@/components/senttotwc/SentToTWC'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/components/admin/Admin.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/admin/:type',
      name: 'adminType',
      component: () => import('@/components/admin/Admin.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/admin/:type/:vandatypeid/bracket-types',
      name: 'vandaTypeBracketTypes',
      component: () => import('@/components/admin/Admin.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/admin/:type/:vandatypeid/pricing',
      name: 'vandaTypePricing',
      component: () => import('@/components/admin/Admin.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /* Order Routes */
    {
      path: '/orders',
      name: 'Orders',
      component: () => import('@/components/orders/Orders'),
      meta: {
        title: 'Orders',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/orders/details/:id',
      name: 'OrderDetails',
      component: () => import('@/components/orders/OrderDetails'),
      meta: {
        title: 'Order Details',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/orders/details/:id/add-product',
      name: 'order-add-product',
      component: () => import('@/components/quotes/AddProduct'),
      meta: {
        title: 'Add Product',
        resource: 'Admin',
        action: 'read'
      }
    },
    /* Item Routes */
    {
      path: '/items',
      name: 'Items',
      component: () => import('@/components/items/Items'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/items/new',
      name: 'NewItem',
      component: () => import('@/components/items/NewItem'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/items/:id',
      name: 'EditItem',
      component: () => import('@/components/items/EditItem'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /* Free Sample Routes */
    {
      path: '/samples',
      name: 'samples',
      component: () => import('@/components/samples/samples'),
      meta: {
        title: 'Samples',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/samples/details/:id',
      name: 'samples-details',
      component: () => import('@/components/samples/SamplesDetails'),
      meta: {
        title: 'Sample Details',
        resource: 'Admin',
        action: 'read'
      }
    },
    /* Price Routes */
    {
      path: '/prices/:fabricCategoryID',
      name: 'Prices',
      component: () => import('@/components/admin/prices/Prices'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/prices/new',
      name: 'New Price',
      component: () => import('@/components/admin/prices/NewPrice'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/prices/:id',
      name: 'Edit Price',
      component: () => import('@/components/admin/prices/EditPrice'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /* Customer Routes */
    {
      path: '/customers',
      name: 'Customers',
      component: () => import('@/components/customers/Customers'),
      meta: {
        title: 'Customers',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/customers/details/:customerId',
      name: 'Customers Details',
      component: () => import('@/components/customers/CustomersDetails'),
      meta: {
        title: 'Customers Details',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/customers/add',
      name: 'Add Customers',
      component: () => import('@/components/customers/CustomersAdd'),
      meta: {
        title: 'Add Customer',
        resource: 'Admin',
        action: 'read'
      }
    },
    // {
    //   path: '/customers-details',
    //   name: 'Customers Details',
    //   component: () => import('@/components/customers/CustomersDetails')

    // },
    // {
    //   path: '/customersold',
    //   name: 'Old Customer',
    //   component: CustomersOld
    // },
    // {
    //   path: '/customers/import',
    //   name: 'Import Customers',
    //   component: ImportCustomers
    // },
    /* {
      path: '/customers/new',
      name: 'New Customer',
      component: NewCustomer
    },
    {
      path: '/customers/:id',
      name: 'Customer',
      component: Customer
    },
    {
      path: '/add-customers',
      name: 'Add Customer',
      component: () => import('@/components/customers/CustomersAdd'),
    }, */
    /* Quote Routes */
    // {
    //   path: '/quotesOld', // Old
    //   name: 'QuotesOld',
    //   component: QuotesOld
    // },
    // {
    //   path: '/quotesOld/new', // Old
    //   name: 'New Quote Old',
    //   component: QuoteOld
    // },
    // {
    //   path: '/quotesOld/edit/:id', // Old
    //   name: 'Edit Quote Old',
    //   component: QuoteOld
    // },
    /* Quote Routes New */
    // {
    //   path: '/quotesprev', // Old
    //   name: 'QuotesPrev',
    //   component: Quotes
    // },
    {
      path: '/quotes',
      name: 'Quotes',
      component: () => import('@/components/quotes/Quote'),
      meta: {
        title: 'Quotes',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/quotes/details/:id',
      name: 'Add Quote',
      component: () => import('@/components/quotes/AddQuote'),
      meta: {
        title: 'Add Quote',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/add-product/:id',
      name: 'Add poduct',
      component: () => import('@/components/quotes/AddProduct'),
      meta: {
        title: 'Add Product',
        resource: 'Admin',
        action: 'read'
      }
    },
    // {
    //   path: '/quotes2/new', // Old
    //   name: 'New Quote',
    //   component: QuoteOld2
    // },
    // {
    //   path: '/quotes2/edit/:id', // Old
    //   name: 'Edit Quote',
    //   component: QuoteOld2
    // },
    /* Archive Routes */
    {
      path: '/archive',
      name: 'Archive',
      component: () => import('@/components/Archive'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /* TWC Routes */
    {
      path: '/twc/:id',
      name: 'TWC API',
      component: () => import('@/components/admin/TWCAPI'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/sendtofactory/:id',
      name: 'Send To Factory',
      component: () => import('@/components/admin/SendToFactory'),
      meta: {
        title: 'Send to Factory',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/postcodes',
      name: 'Postcodes',
      component: () => import('@/components/admin/Postcodes'),
      meta: {
        title: 'Postcodes',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/promocodes',
      name: 'Promocodes',
      component: () => import('@/components/admin/Promocodes'),
      meta: {
        title: 'Promocodes',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/installprices',
      name: 'InstallPrices',
      component: () => import('@/components/admin/Installprices'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /** Fabric Routes */
    // {
    //   path: '/fabrics',
    //   name: 'Fabrics',
    //   component: () => import('@/components/admin/fabrics/Fabrics'),
    //   meta: {
    //     resource: 'Admin',
    //     action: 'read'
    //   }
    // },
    {
      path: '/fabric/new',
      name: 'New Fabric',
      component: () => import('@/components/admin/fabrics/NewFabric'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/fabric/:fabricID',
      name: 'Edit Fabric',
      component: () => import('@/components/admin/fabrics/EditFabric'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /** Colour Routes */
    // {
    //   path: '/colours/:fabricID',
    //   name: 'Colours',
    //   component: () => import('@/components/admin/colours/Colours'),
    //   meta: {
    //     resource: 'Admin',
    //     action: 'read'
    //   }
    // },
    {
      path: '/newColor',
      name: 'New Color',
      component: () => import('@/components/admin/colours/NewColor'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/editColor/:colorID',
      name: 'Edit Color',
      component: () => import('@/components/admin/colours/EditColor'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /** Free Sample Pack Routes */
    {
      path: '/freeSamplePacks',
      name: 'Free Sample Packs',
      component: () => import('@/components/admin/FabricCategories'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    /** Fabric Category Routes */
    {
      path: '/fabricCategories',
      name: 'Fabric Categories',
      component: () => import('@/components/admin/freeSamplePacks/FreeSamplePacks'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/customCurtains',
      name: 'Custom Curtains',
      component: () => import('@/components/admin/CustomCurtains'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/components/Dashboard'),
      meta: {
        resource: 'Auth',
        action: 'read'
      }
    },
    {
      path: '/dashboard-new',
      name: 'Dashboard New',
      component: () => import('@/components/Dashboard-New'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/dashboard/sales',
      name: 'Dashboard Sales',
      component: () => import('@/components/Dashboard'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    // {
    //   path: '/dashboard/installation',
    //   name: 'InstallDashboard',
    //   component: InstallDashboard
    // },
    {
      path: '/dashboard/installation',
      name: 'Install Dashboard',
      component: () => import('@/components/InstallDashboardNew'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/dashboard/account',
      name: 'AccountDashboard',
      component: () => import('@/components/AccountDashboard'),
      meta: {
        title: 'Account Dashboard',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/dashboard/motorisation',
      name: 'Motorisation Dashboard',
      component: () => import('@/components/MotorisationDashboard'),
      meta: {
        title: 'Motorisation Dashboard',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/import/orders',
      name: 'Import Orders',
      component: () => import('@/components/admin/import/Orders'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/import/items',
      name: 'Import Items',
      component: () => import('@/components/admin/import/Items'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/carts',
      name: 'Carts',
      component: () => import('@/components/carts/Carts'),
      meta: {
        title: 'Carts',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/components/Account'),
      meta: {
        title: 'Account Settings',
        resource: 'Auth',
        action: 'read'
      }
    },
    {
      path: '/fulfilments',
      name: 'Fulfilments',
      component: () => import('@/components/fulfilment/Fulfilment'),
      meta: {
        title: 'Fulfilments',
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/fulfilments/details',
      name: 'Fulfilments Details',
      component: () => import('@/components/fulfilment/FulfilmentDetails'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/installer-dashboard',
      name: 'Installer Dashboard',
      component: () => import('@/components/dashboard/Installer'),
      meta: {
        resource: 'Installer',
        action: 'read'
      }
    },
    // --------------------
    // Check measure
    // --------------------
    {
      path: '/check-measure/:id',
      name: 'check-measure-details',
      component: () => import('@/components/check-measure/CheckMeasureDetails'),
      meta: {
        resource: 'Auth',
        action: 'read'
      }
    },
    {
      path: '/check-measure/:id/product/add',
      name: 'add-check-measure-product',
      component: () => import('@/components/check-measure/AddCheckMeasureProduct'),
      meta: {
        resource: 'Installer',
        action: 'read'
      }
    },
    {
      path: '/check-measure/:id/products',
      name: 'check-measure-product',
      component: () => import('@/components/check-measure/CheckMeasureProductDetails'),
      meta: {
        resource: 'Installer',
        action: 'read'
      }
    },
    // ----------------------
    // Installer
    // ----------------------
    {
      path: '/installer',
      name: 'Installer',
      component: () => import('@/components/installers/Installer'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/installer/details/:installerID',
      name: 'Installer Details',
      component: () => import('@/components/installers/InstallerDetails'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/installer/add',
      name: 'Add Installer',
      component: () => import('@/components/installers/AddInstaller'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    // ----------------------
    // Installations
    // ----------------------
    {
      path: '/installations',
      name: 'Installations',
      component: () => import('@/components/installations/Installations'),
      meta: {
        resource: 'Admin',
        action: 'read'
      }
    },
    {
      path: '/installation/:id',
      name: 'installation-details',
      component: () => import('@/components/installations/InstallationDetails'),
      meta: {
        resource: 'Auth',
        action: 'read'
      }
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/components/errors/NotAuthorized.vue'),
      meta: {
        resource: 'Auth',
        action: 'read'
      }
    }
  ]
})

const sendtofactorynewRoute = {
  path: '/sendtofactory/:id/new',
  name: 'send-to-factory-new',
  component: () => import('@/components/admin/send-to-factory/SendToFactoryNew'),
  meta: {
    title: 'Send to Factory New',
    resource: 'Admin',
    action: 'read'
  }
}

// not include rework routes in production
if (env.MODE !== 'production') {
  router.addRoute(sendtofactorynewRoute)
  router.addRoutes(reworkRouutes)
}

router.beforeEach((to, _, next) => {
  document.title = to.meta.title || to.name
  const idToken = localStorage.getItem('id_token')

  if (!canNavigate(to)) {
    if (!idToken) return next({ name: 'Login' })

    // if home page link then redirect to dashboard page
    if (to.path === '/') {
      return next({ name: 'Dashboard' })
    }

    return next({ name: 'not-authorized' })
  }

  return next()
})

export default router
