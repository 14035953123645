import axios from 'axios'
import router from './../router/index'
import { env } from '@/env'

export default() => {
  const axiosInstant = axios.create({
    baseURL: env.API_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('id_token'),
    },
  })

  // Add interceptor for each request. If request is not authorized then redirect to login and clear the token
  axiosInstant.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('id_token')
      router.push({ name: 'Login', params: {error: 'logout'} })
    }
    return Promise.reject(error)
  })

  return axiosInstant
}
