import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './user'
import webSocketModule from './webSocket'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  namespace: true,
  modules: {
    user: userModule,
    webSocket: webSocketModule
  },
  state: {
    drawer: true,
    customers: {},
    users: {},
    mini: true,
    refreshNotification: 0
  },
  mutations: {
    toggleDrawer (state) {
      state.drawer = !state.drawer
    },
    toggleMini (state, paylod) {
      state.mini = paylod
    },
    setCustomers (state, customers) {
      state.customers = customers
    },
    setUsers (state, users) {
      state.users = users
    },
    refreshNotificationCount (state) {
      state.refreshNotification++
    }
  },
  actions: {
    TOGGLE_DRAWER ({ commit }) {
      commit('toggleDrawer')
    },
    TOGGLE_MINI ({ commit }, paylod) {
      commit('toggleMini', paylod)
    }
  },
  getters: {
    DRAWER_STATE (state) {
      return state.drawer
    },
    allCustomers (state) {
      // console.log(state.customers)
      return state.customers
    },
    allUsers (state) {
      return state.users
    }
  }
})
