// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import VueQuillEditor from 'vue-quill-editor'
// 3rd party plugins
import '@/libs/acl'

import App from './App'
import router from './router'
import store from './store'
import { logoutUser } from './auth'

import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { env } from './env'
import { logger } from './utils/logger'
const moment = require('moment-timezone')

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#E83966',
        secondary: '#444C5F',
        accent: '#444C5F',
        error: '#b71c1c',
        grey: '#444C5F',
        themeBlue: '#323c53'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0
})

Vue.mixin({
  computed: {
    NODE_ENV () {
      return env.MODE || 'development'
    },
    CMS_MEDIA_URL() {
      return env.CMS_MEDIA_URL
    }
  },
  methods: {
    float2: number => parseFloat(parseFloat(number).toFixed(2)) || 0,
    formatPrice: (number) => priceFormatter.format(number),
    formatNumberWithComma: (number) => numberFormatter.format(number),
    min_shipping (date = null) {
      if (date === null) return 30
      const targetDate = moment.tz(date, 'Australia/Sydney')
      const cutOffDate = moment.tz('2023-03-22', 'Australia/Sydney')
      return targetDate.isSameOrBefore(cutOffDate, 'day') ? 30 : 50
    }
  }
})

Vue.prototype.$logoutUser = logoutUser

Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueQuillEditor)


Vue.config.errorHandler = function (err, vm) {
  const user = JSON.parse(atob(localStorage.getItem('user')))
	const currentTime = new Date().toISOString()

	// Function to extract the component name from the Vue instance
	const getComponentName = (vm) => {
		if (vm.$root === vm) return 'root'
		const name = vm._isVue
			? (vm.$options && vm.$options.name) || (vm.$options && vm.$options._componentTag)
			: vm.name
		return (
			(name ? `<${name}>` : `anonymous component`) +
			(vm._isVue && vm.$options && vm.$options.__file ? ` at ${vm.$options.__file}` : '')
		)
	}

	// Function to extract the function name from the error stack
	const getFunctionName = (err) => {
		const stackLines = err.stack.split('\n')
		if (stackLines.length > 1) {
			const functionLine = stackLines[1].trim()
			const functionName = functionLine.split(' ')[1]
			return functionName
		}
		return 'unknown function'
	}

  const errorMessage = err.message || err.toString()

  logger.error(`${errorMessage} - ${currentTime}`, {
    category: 'error',
    status: 'failed',
    mode: env.MODE,
		errorMessage,
		errorTime: currentTime,
		errorSource: getComponentName(vm) + getFunctionName(err),
		errorStack: JSON.stringify(err.stack),
    user
	})
}


/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router,
  components: { App },
  store,
  template: '<App/>'
})
