<template>
  <div>
    <v-snackbar class="notification-snackbar" v-model="notification.show" :timeout="notification.timeout" variant="outlined" right bottom>
      <!-- <span v-html="notification.message"></span> -->
      <span class="pr-2">
        <template v-for="(part, index1) in getMessageParts(notification.message)">
          <span v-if="part.type === 'text'" :key="index1">{{ part.content }} </span>
          <span v-else-if="part.type === 'link'" class="order-link primary--text cp" @click="goTo(part.orderId, part.customerID)" :key="index1">
            {{ part.content }}
          </span>
          <span v-else-if="part.type === 'see_note'" class="see-note-link primary--text cp" v-html="part.content" @click="seeNote(part.orderId, part.customerID)" :key="index1">
          </span>
          <span v-else-if="part.type === 'see_all_notifications'" class="see-all-notification-link primary--text cp" v-html="part.content" @click="goToNotifications()" :key="index1">
          </span>
        </template>
      </span>
      <v-btn icon class="close-button cp" @click="notification.show = false">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import NotificationService from '@/services/NotificationService'

export default {
  name: 'App',
  data () {
    return {
      // drawer: false,
      loggedInUser: JSON.parse(atob(localStorage.getItem('user'))),
      loggedIn: localStorage.getItem('id_token'),
      notification: {
        show: false,
        message: '',
        timeout: 53000
      },
      socket: null
    }
  },
  mounted () {
    if (this.loggedIn) {
      this.setUserIdInStore()
      this.initializeWebSocket()
      this.getUnreadNotificationsCount()
    }
  },
  computed: {
    websocket () {
      return this.$store.state.webSocket.websocket
    },
    refreshNotificationCount () {
      return this.$store.state.refreshNotification
    }
  },
  watch: {
    refreshNotificationCount () {
      this.getUnreadNotificationsCount()
    },
    websocket () {
      // Now you can use this.websocket in this component
      this.websocket.addEventListener('message', (event) => {
        // Handle WebSocket messages here
        const receivedData = JSON.parse(event.data)
        if (receivedData.notification && receivedData.notification !== 'heartbeat') {
          console.log('notification received')
          // Handle the received notification in your application
          this.notification.message = receivedData.notification
          this.notification.show = true
          this.getUnreadNotificationsCount()
        }
      })

      this.websocket.addEventListener('close', () => {
        // WebSocket connection is closed
      })
      this.websocket.onerror = (error) => {
        console.error('WebSocket error:', error)
      }
    }
  },
  beforeDestroy () {
    if (this.websocket) {
      this.websocket.close()
    }
  },
  methods: {
    async getUnreadNotificationsCount () {
      const response = await NotificationService.getUnreadNotificationsCount(this.loggedInUser._id)
      const notificationCount = response.data.count
      this.$store.commit('user/UPDATE_USER_NOTIFICATION_COUNT', { notificationCount })
    },
    setUserIdInStore () {
      this.$store.commit('webSocket/setUserId', this.loggedInUser._id)
    },
    async initializeWebSocket () {
      await this.$store.dispatch('webSocket/connectWebSocket')
    },
    getMessageParts (notification) {
      const parts = []
      if (notification && notification.orderID) {
        const firstName = notification.userID.firstName
        const orderCode = notification.orderID.orderCode
        const orderId = notification.orderID._id

        // Add the parts of the message
        parts.push({ type: 'text', content: `${firstName} mentioned you in a note for order` })
        parts.push({ type: 'link', content: orderCode, orderId: orderId })
        parts.push({ type: 'see_note', content: '<br><br>See note', noteID: '', orderId: orderId })
        parts.push({ type: 'see_all_notifications', content: '<br>See all notifications' })
      } else if (notification && notification.type === 'Note' && !notification.orderID) {
        const firstName = notification.userID.firstName
        const customerID = notification.customerID._id
        const customerName = `${notification.customerID.firstName} ${notification.customerID.lastName}`
        parts.push({ type: 'text', content: `${firstName} mentioned you in a note for customer` })
        parts.push({ type: 'link', content: customerName, customerID: customerID })
        parts.push({ type: 'see_note', content: '<br><br>See note', noteID: '', customerID: customerID })
        parts.push({ type: 'see_all_notifications', content: '<br>See all notifications' })
      } else {
        parts.push({ type: 'text', content: `${notification}` })
      }

      return parts
    },
    goTo (orderId, customerID) {
      if (this.notification.message.noteType === 'General') {
        this.$router.push({ path: `/customers/details/${customerID}#notes` })
      } else {
        this.$router.push({ path: `/orders/details/${orderId}#notes` })
      }
    },
    goToNotifications () {
      this.$router.push({ path: `/dashboard#notification-centre` })
    },
    seeNote (orderId, customerID) {
      this.goTo(orderId, customerID)
    }
  }
}
</script>
<style scoped>
.notification-snackbar {
  bottom: 10px;
}
.notification-snackbar .v-sheet.v-snack__wrapper{
  border-radius: 20px;
  background: white;
  color: #444C5F;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
.close-button .v-icon {
  color: #444C5F !important;
}
</style>>
