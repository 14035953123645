export const env = {
    NODE_ENV: process.env.NODE_ENV,
    API_URL: process.env.VUE_APP_API_URL,
    MEDIA_URL: process.env.VUE_APP_MEDIA_URL,
    S3_BUCKET: process.env.VUE_APP_S3_BUCKET,
    S3_CMS_BUCKET: process.env.VUE_APP_S3_CMS_BUCKET,
    CMS_MEDIA_URL: process.env.VUE_APP_CMS_MEDIA_URL,
    WEBSOCKET_URL: process.env.VUE_APP_WEBSOCKET_URL,
    MODE: process.env.VUE_APP_MODE,
    LOGTAIL_SOURCE_TOKEN: process.env.VUE_APP_LOGTAIL_SOURCE_TOKEN,
    INSTALL: false,
    EX_GST: false,
    MOTORIZATION: true
}