import { resetUserAbilities } from '@/libs/acl/utils'
import { logger } from './utils/logger'

export function logoutUser () {
  // your logout logic here
  console.log('User logged out')
  const user = JSON.parse(atob(localStorage.getItem('user')))
  window.localStorage.setItem('authenticated', false)
  localStorage.removeItem('id_token')
  localStorage.removeItem('is_admin')
  localStorage.removeItem('user')
  this.$store.commit('user/REMOVE_USER')
  resetUserAbilities() // reset user abilities
  logger.info(`Logout - ${user.firstName} ${user?.lastName}`, {
    category: 'Logout',
    status: 'success',
    userId: user._id,
    email: user?.email,
    firstName: user.firstName,
    lastName: user?.lastName
  })
  this.$router.push({ name: 'Login', params: { error: 'logout' } })
}
