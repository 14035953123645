<template>
  <div>
    <v-dialog
      v-model="stayLoggedInDialog"
      persistent
      max-width="350"
      content-class="rounded-0"
    >
      <v-card class="py-3 rounded-0">
        <v-card-text class="dialog__card-text">
          <strong>
            Please click button if you wish to stay logged in
          </strong>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            rounded
            center
            color="primary"
            class="dialog__card-btn"
            @click="reLogin"
          >
            {{timerCountDown}} seconds
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import AuthService from '@/services/AuthService.js'

export default {
  name: 'StayLoggedIn',
  data () {
    return {
      intervalId: null,
      elapsedTime: null,
      loginTime: null,
      loggedInUser: JSON.parse(atob(localStorage.getItem('user'))),
      stayLoggedInDialog: false,
      timerCountDown: 30,
      stayLoggedInHours: 9
    }
  },
  created () {
    // Initialize loginTime from localStorage when the component is created
    let loginTime = JSON.parse(localStorage.getItem('loginTime'))

    // The old format (Tue Jul 23 2024 16:40:47 GMT+0530) which we were using to store logintime is drepected
    // It was causing issue while parsing so now we have changed the logintime format to timestamp
    // This case is for user which have store the logintime in old format where we check is it
    // With new format if no then update the time to with current time +5 minute and store it
    // TODO: we can remove this once we are confident that no user have old format
    if (loginTime && !moment(loginTime, moment.ISO_8601, true).isValid()) {
      loginTime = moment().add(5, 'minutes').format()
      localStorage.setItem('loginTime', loginTime)
    }
  
    this.loginTime = loginTime
      ? moment(loginTime)
      : null

    // If the user is logged in, start the interval
    if (this.isLoggedIn) {
      this.intervalId = setInterval(() => {
        // Update the elapsedTime by calling the local method
        const now = moment()
        this.elapsedTime = this.loginTime 
          ? now.diff(this.loginTime, 'seconds')
          : null 
      }, 1000)
    }
  },
  beforeDestroy () {
    // Make sure to stop the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  computed: {
    isLoggedIn () {
      return this.loginTime !== null
    }
  },
  watch: {
    elapsedTime (val) {
      const nineHoursInSeconds = this.stayLoggedInHours * 60 * 60 // hours in seconds
      this.timerCountDown = nineHoursInSeconds - val
      if (val > nineHoursInSeconds - 30) { // show dialog before 30 seconds
        this.stayLoggedInDialog = true
      }
      if (val > nineHoursInSeconds) {
        this.$logoutUser()
      }
    }
  },
  methods: {
    async reLogin () {
      const response = await AuthService.reLogin({
        userID: this.loggedInUser._id
      })

      if (response.data.success) {
        localStorage.setItem('id_token', response.data.id_token)
        this.loginTime = moment().format()
        localStorage.setItem('loginTime', this.loginTime)
        this.stayLoggedInDialog = false
      }
    },
  },
}
</script>
