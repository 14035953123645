import { env } from '@/env'
export default {
  namespaced: true,
  state: {
    websocket: null,
    userId: null, // Replace with your actual user ID
  },
  mutations: {
    setWebSocket(state, websocket) {
      state.websocket = websocket
    },
    setUserId(state, userId) {
      state.userId = userId
    },
  },
  actions: {
    connectWebSocket({ commit, state }) {
      const websocket = new WebSocket(env.WEBSOCKET_URL)

      websocket.onopen = () => {
        console.log('WebSocket connection opened')
        const payload = {
          action: 'saveUser',
          userId: state.userId,
        }
        websocket.send(JSON.stringify(payload))

        // Send a heartbeat message every 30 seconds
        const heartbeatInterval = setInterval(() => {
          if (websocket.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({ action: 'heartbeat' }))
          }
        }, 60000)

        // Handle incoming messages
        websocket.onmessage = (event) => {
          const message = JSON.parse(event.data)
          if (message.notification === 'heartbeat') {
            console.log(
              '⚡️ Using ❤️ from a WebSocket to prevent inactivity-based connection closures.'
            )
          } else {
            // Handle other message types...
          }
        }

        // Handle WebSocket connection close
        websocket.onclose = () => {
          console.log('WebSocket connection closed.')
          clearInterval(heartbeatInterval)
        }

        commit('setWebSocket', websocket)
      }

      // Handle other WebSocket events here

      return websocket
    },
  },
}
